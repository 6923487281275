/* Menu.css */
.btn11 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    border-radius: 10px;
    height: 50px;
    /*top: 300px;*/
    /*left: 240px;*/
    transition-duration: 0.5s;
    /*background-color: #424855;*/
}

.btn11 .icon-left,
.btn11 .icon-right {
    transition-duration: 0.5s;
    position: relative;
    height: 3px;
    width: 12px;
    /*top: 30px;*/
    background-color: #A6ACBB;
}

.btn11 .icon-left {
    left: 0;
}

.btn11 .icon-right {
    /*left: 15px;*/
}

.btn11 .icon-left:before,
.btn11 .icon-left:after,
.btn11 .icon-right:before,
.btn11 .icon-right:after {
    transition-duration: 0.5s;
    position: absolute;
    width: 12px;
    height: 3px;
    background-color: #A6ACBB;
    content: "";
}

.btn11 .icon-left:before,
.btn11 .icon-right:before {
    top: -8px;
}

.btn11 .icon-left:after,
.btn11 .icon-right:after {
    top: 8px;
}

.btn11.open .icon-left,
.btn11.open .icon-right {
    transition-duration: 0.5s;
    background: transparent;
}

.btn11.open .icon-left:before {
    transform: rotateZ(45deg) scaleX(1.4) translate(2px, 2px);
}

.btn11.open .icon-left:after {
    transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -2px);
}

.btn11.open .icon-right:before {
    transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 2px);
}

.btn11.open .icon-right:after {
    transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -2px);
}

.btn11:hover {
    cursor: pointer;
}