nav{
    display: flex;
    justify-content: space-between;
    /*background-color: #2A313D;*/
    color: #A6ACBB;
    padding-right: 20px;
    padding-left: 10px;
    height: 75px;
    /*align-items: center;*/
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
}
.nav-name-container{
    display: flex;
    /*background-color: peru;*/
    align-items: center;
    font-size: 1.8rem;
    /*line-height: 1.75rem;*/
    cursor: pointer;
    /*background-color: green;*/
    /*height: 65%;*/
}
.nav-name{
    /*background-color: peru;*/
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65%;
    padding: 0 15px;
    background-color: transparent;
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out;

}
.nav-name:hover{
    background-color: rgba(85, 84, 84, 0.41);
    border-radius: 10px;
    padding: 0 15px;
    display: flex;
    align-items: center;

}
.nav-name-container:hover{

}
.nav-links-container{
    display: flex;
    justify-content: space-between;
    /*background-color: green;*/
    align-items: center;
    gap: 0.5rem;
}
.nav-links{
    display: flex;
    align-items: center;
    /*background-color: #6518E6;*/
    border-radius: 10px;
    padding: 0 20px ;
    height: 65%;
    font-size: 1.25rem;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
}
.nav-links:hover{
    background-color: rgba(85, 84, 84, 0.41);
    cursor: pointer;
}
.nav-links-clicked{
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #6518E6;
    border-radius: 10px;
    padding: 0 20px ;
    height: 65%;
    font-size: 1.25rem;
}

.montserrat-thick {
                 font-family: "Montserrat", sans-serif;
                 font-optical-sizing: auto;
                 font-weight: 700;
                 font-style: normal;

             }
.different{
    color: hsl(262, 80%, 50%);
}
.theme-parent{
display: flex;
    align-items: center;
}
.theme-container{
    display: flex;
    /*background-color: green;*/
    padding: 0 15px;
    cursor: pointer;
    border-radius: 10px;
    align-items: center;
    height: 65%;
    gap: 5px;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;

}
.theme-container:hover{
    background-color: rgba(85, 84, 84, 0.41);
}
.hamburger-menu{
    display: none;
}














.nav-links-container-menu{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 55px;
    left: 20px;
    justify-content: space-between;
    background-color: #424855;
    border-radius: 15px;
    padding: 5px;
    align-items: center;
    width: 60%;
    gap: 0.5rem;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

}
.nav-links-container-menu-invi{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 55px;
    left: 20px;
    justify-content: space-between;
    background-color: #424855;
    border-radius: 15px;
    padding: 5px;
    align-items: center;
    width: 60%;
    gap: 0.5rem;
    opacity: 0;
    transition: opacity  0.3s ease-in-out;

}
.nav-links-menu{
    display: flex;
    align-items: center;
    /*background-color: #6518E6;*/
    border-radius: 5px;
    padding: 0 20px ;
    height: 65%;
    font-size: 1.25rem;
    width: 100%;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
}
.nav-links-menu:hover{
    background-color: rgba(85, 84, 84, 0.41);
    cursor: pointer;
}
.nav-links-clicked-menu{
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #6518E6;
    border-radius: 10px;
    padding: 0 20px ;
    height: 65%;
    width: 100%;
    font-size: 1.25rem;
}
@media (max-width: 900px) {
    nav{
        display: flex;
        justify-content: space-between;
        /*background-color: #2A313D;*/
        color: #A6ACBB;
        /*padding-right: 0px;*/
        /*padding-left: 0px;*/
        height: 60px;
        /*align-items: center;*/
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10000;
        width: 100%;
    }
    .hamburger-menu{
        display: flex;
        flex-direction: column;
    }
    .nav-links-container{
        display: none;
    }
    .nav-name-container{
        display: flex;
        /*background-color: peru;*/
        align-items: center;
        font-size: 1.5rem;
        /*line-height: 1.75rem;*/
        cursor: pointer;
        /*background-color: green;*/
        /*height: 65%;*/
    }
    .nav-name{
        /*background-color: peru;*/
        display: flex;
        justify-content: center;
        align-items: center;
        height: 65%;
        padding: 0;
        background-color: transparent;
        border-radius: 10px;
        transition: background-color 0.3s ease-in-out;

    }
    .nav-name:hover{
        background-color: transparent;
        border-radius: 10px;
        padding: 0 0px;
        display: flex;
        align-items: center;

    }
    /*.nav-space{*/
    /*    position: absolute;*/
    /*    top: 0;*/
    /*    height: 100vh;*/
    /*    width: 100vh;*/
    /*    !*background-color: greenyellow;*!*/
    /*    left: 0;*/
    /*    z-index: -50;*/
    /*}*/
}