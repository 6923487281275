.code-portion{
    background-color: #181D25;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 410px;
    height: auto;

}
.pd-extra{
    padding-top: 6rem;
    padding-bottom:6rem;
}

.name-img-container{
    display: flex;
align-items: center;
    gap: 5px;
}
.dots-container{
    display: flex;
    /*background-color: green;*/
    gap: 10px;
    margin-bottom: 20px;
}
.dots{
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #2A313D;
}
.code-line{
    display: flex;
    font-size: 16px;
    margin-bottom: 3px;
}
.mar-rig{
    margin-right: 20px;
}
.yellow{
    color: #dfdf00;
}
.green{
    color: #00ff00;
}
.redd{
    background-color: #EE6C58;

}
.yellowd{
    background-color: #F2BF50;
}
.greend{
    background-color: #60C453;
}
.faint{
    color: rgba(198, 196, 196, 0.39);
}
.inconsolata-font {
                  font-family: "Inconsolata", monospace;
                  font-optical-sizing: auto;
                  font-weight: 400;
                  font-style: normal;
                  font-variation-settings:
                          "wdth" 100;
              }
.width-btn{
    width: 410px;
}
.my-name{
    font-size: 3rem;
    color: #A6ADBA;
}
.btn-container{
    display: flex;
    justify-content: center;
    align-items: center;

}
.image-container{
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-color: #2A313D;
    margin-bottom: 30px;
    border: 2px solid hsla(262, 80%, 50%, 0.31);
}



@media (max-width: 900px) {
    .code-portion{
        background-color: #181D25;
        margin-top: 10px;
        margin-bottom: 20px;
        padding: 15px 15px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        width: 80%;
        height: auto;

    }
    .width-btn{
        width: 80%;
    }
    .my-name{
        font-size: 2rem;
        color: #A6ADBA;
    }
    .image-container{
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: #2A313D;
        margin-bottom: 20px;
        border: 2px solid hsla(262, 80%, 50%, 0.31);
    }
    .pd-extra{
        padding-top: 4rem;
        padding-bottom:6rem;
    }

}





















