.project-content
{
    display: flex;
    /*background-color: #475569;*/
    width: 82%;
    border-radius: 10px;
    flex-wrap: wrap;
    padding-top: 20px;
    justify-content: center;
    gap: 45px;
    overflow:auto;
    /*height: 680px;*/
}
.project-content::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}
.no-scroll::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}
.project-content-item{
    width: 430px;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.27);
    border-radius: 15px;
    display: flex;
    /*justify-content: center;*/
    /*align-items: center;*/
    text-align: center;

    /*padding: 0px 35px;*/
    flex-direction: column;
}
.project-content-item:hover{
    background-color: rgba(0, 0, 0, 0.52);
}
.project-title{
    font-size: 24px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    transition: color 0.3s ease-in-out;
}
.project-title-invisible{
    font-size: 24px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    color: transparent;
    transition: color 0.1s ease-in-out;
}
.project-des {
    font-size: 18px;
    font-weight: 700;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
    color: transparent;
}

.project-des-hovered {
    transform: translateY(0);
    color: inherit;
}


.tech-stack-2{
    color: white;
    background-color:#6518E6;
    border-radius: 10px;
    padding:1px 12px;
    font-weight: 700;
    font-size: 0.875rem;
    cursor: default;

}
.tech-stack-2-invisible{
    color: transparent;
    background-color:transparent;
    border-radius: 10px;
    padding:1px 12px;
    font-weight: 700;
    font-size: 0.875rem;
    cursor: default;

}


.service-image{
    height: 180px;
    width: auto;
}
.service-image-title{
    /*background-color:green;?*/
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 30px;
}
.project-bottom-ender{
    /*width: 12?0%;*/
    height: 20%;
    padding: 0 25px;
    background-color: rgba(0, 0, 0, 0.38);
    margin-top: auto;
    border-radius: 0 0 15px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: transform 0.3s ease-in-out;
}
.project-bottom-ender-invisible{
    height: 20%;
    padding: 0 25px;
    background-color: transparent;
    margin-top: auto;
    border-radius: 0 0 15px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateY(100%);
    /*transition: background-color 0.3s ease-in-out;*/

}
.code-linker{
    border-radius: 50%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 45px;
    background-color:  hsla(316, 70%, 50%, 1);
}
.code-linker-invisible{
    border-radius: 50%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 45px;
    background-color: transparent;
}
.tech-stack {
    color: transparent;
    background-color: transparent;
    border-radius: 10px;
    padding: 1px 8px;
    font-weight: 700;
    font-size: 0.875rem;
    cursor: default;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.tech-stack-project {
    padding: 0 35px;
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    transform: translateY(120%);
    transition: transform 0.3s ease-in-out;
}

.tech-stack-project.visible {
    transform: translateY(0);
}

.tech-stack-project.visible .tech-stack {
    color: white;
    background-color: hsla(316, 70%, 50%, 1);
}

.project-bottom-ender {
    height: 20%;
    padding: 0 25px;
    background-color: transparent;
    margin-top: auto;
    border-radius: 0 0 15px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.project-bottom-ender.visible {
    background-color: rgba(0, 0, 0, 0.38);
    transform: translateY(0);
}

.tech-stack-2 {
    color: transparent;
    background-color: transparent;
    border-radius: 10px;
    padding: 1px 12px;
    font-weight: 700;
    font-size: 0.875rem;
    cursor: default;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.project-bottom-ender.visible .tech-stack-2 {
    color: white;
    background-color: #6518E6;
}

.code-linker {
    border-radius: 50%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 45px;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
}

.project-bottom-ender.visible .code-linker {
    background-color: hsla(316, 70%, 50%, 1);
}

.code-linker svg {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.project-bottom-ender.visible .code-linker svg {
    opacity: 1;
}
.tech-stack-3 {
    color: white;
    background-color: deepskyblue;
    border-radius: 10px;
    padding: 1px 12px;
    font-weight: 700;
    font-size: 0.875rem;
    cursor: default;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.project-content-item {
    position: relative;
    overflow: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(5, 5, 5, 0.76);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.overlay.visible {
    opacity: 1;
}
.project-title,
.project-des,
.tech-stack-project,
.project-bottom-ender {
    position: relative;
    z-index: 1;
}
/*.project-des{*/
/*    margin-bottom: 30px;*/
/*    font-size: 22px;*/
/*}*/
@media (max-width: 515px){
    .project-width{
        width: 90%;
        /*color: greenyellow;*/
    }
    .project-title{
        font-size: 20px;
        font-weight: 700;
        margin-top: 10px;
        margin-bottom: 10px;
        transition: color 0.3s ease-in-out;
    }
    .project-title-invisible{
        font-size: 20px;
        font-weight: 700;
        margin-top: 10px;
        margin-bottom: 10px;
        color: transparent;
        transition: color 0.1s ease-in-out;
    }
    .project-des {
        font-size: 16px;
        font-weight: 700;
        transform: translateY(100%);
        transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
        color: transparent;
        width: 80%;
        /*background-color: greenyellow;*/
        margin-left: auto;
        margin-right: auto;
    }

    .project-des-hovered {
        transform: translateY(0);
        color: inherit;
    }
    .tech-stack-project {
        padding: 0 30px;
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        transform: translateY(120%);
        transition: transform 0.3s ease-in-out;
        gap: 5px;
    }

    .tech-stack-project.visible {
        transform: translateY(0);
    }

    .tech-stack-project.visible .tech-stack {
        color: white;
        background-color: hsla(316, 70%, 50%, 1);
    }



}