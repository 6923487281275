.different{
    color: hsl(262, 80%, 50%);
}
.skills-des{
    margin-bottom: 30px;
    font-size: 22px;
}
.skills-content{
    /*background-color: green;*/
    /*width: 100%;*/
    /*height: 100vh;*/
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    /*overflow: auto;*/

    align-items: center;
}
.skills-items{
    display: flex;
    position: relative;
    /*background-color: peru;*/
    width: 35%;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
}
.skills-image-container{
    height: 50px;
    width: auto;
    /*background-color: per*/
}
.img-adjust{
    height: 62px;
    width:auto;
}
.img-adjust2{
    height: 50px;
    margin-bottom: 12px;
    width: auto;
}
.img-adjust3{
    height: 70px;
    width: auto;
}
.skills-unit{
    height: 120px;
    display: flex;
    gap: 10px;
    /*padding: 10px 10px;*/
    border: 1px solid #a0a3a6;
    flex-direction: column;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    width: 140px;
    background-color: #353A47;
    cursor: pointer;
}
.skills-unit:hover{
    background-color: #545863;
}
@media (max-width: 900px) {
    .skills-des{
        margin-bottom: 30px;
        font-size: 16px;
        text-align: center;
    }
    .skills-items{
        display: flex;
        position: relative;
        /*background-color: peru;*/
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 15px;
        flex-wrap: wrap;
        padding: 10px;
        /*overflow: auto;*/
        /*height: 1200px;*/
        margin-bottom: 3rem;
    }
    .skills-content{
        /*background-color: green;*/
        /*width: 100%;*/
        /*height: 100vh;*/
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: center;
        /*overflow: auto;*/

        align-items: center;
        margin-bottom: 5rem;
    }
    .skills-unit{
        height: 15%;

        display: flex;
        gap: 10px;
        /*padding: 10px 10px;*/
        border: 1px solid #a0a3a6;
        flex-direction: column;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        width: 30%;
        background-color: #353A47;
        cursor: pointer;
    }
}
@media (min-width: 900px) and (max-width: 1700px){
    .skills-items{
        display: flex;
        position: relative;
        /*background-color: peru;*/
        width: 700px;
        align-items: center;
        justify-content: center;
        gap: 15px;
        flex-wrap: wrap;
        padding: 10px;
        /*overflow: auto;*/
        /*height: 1200px;*/
        margin-bottom: 3rem;
    }
}