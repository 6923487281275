.contact-content{
    display: flex;
    margin-top: 50px;
    gap: 50px;
}
.contact-input-container{
    display: flex;
    flex-direction: column;
gap: 10px;
    /*background-color: greenyellow;*/
    width: 440px;
}

.contact-input{
    width: 100%;
    height: 50px;
    outline: none;
    border: 1px solid rgba(170, 168, 168, 0.7);
    border-radius: 10px;
    background-color: transparent;
    padding: 0px 15px;
}
.contact-input-textarea{
    width: 100%;
    border: 1px solid rgba(170, 168, 168, 0.7);
    border-radius: 10px;
    background-color: transparent;
    padding: 10px 15px;
    height: 90px;
}
.contact-input-textarea:focus{
    border: 2px solid #6518E6;
    outline: none;
}
.contact-input:focus{
    border: 2px solid #6518E6;
}
.contact-details{
    /*background-color: green;*/
    justify-content: center;
    /*align-items: center;*/
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.contact-des{
    margin-bottom: 30px;
    font-size: 22px;
    width: 800px;
}
.contact-details-head{
    font-size:x-large;
    line-height: 1.75rem;
    font-weight: 700;
    color: #8798AE;
}
.contact-details-item{
    display: flex;
    gap: 2px;

    flex-direction: column;
    border-bottom: 1px solid #8798AE;
    padding-bottom: 15px;
}
.contact-details-des{
    font-weight:300;
    font-size: 0.8rem;
    line-height: 1rem;
    color: #8798AE;
}
.socials{
    display: flex;
    flex-direction: column;
    /*background-color: green;*/
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
}
.socials-head{
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 2.25rem;
    color: #8798AE;
    margin-bottom: 10px;
}
.links{
    display: flex;
    gap: 10px;
}
.svg-hover:hover {
    stroke: #6518E6; /* Change to your desired color */
    cursor: pointer;
}
.btn-width-contact{
    width: 440px;

}
@media (max-width: 900px)  and (min-width: 500px) {
    .contact-content{
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        gap: 50px;
    }
    .contact-details{
        /*background-color: green;*/
        justify-content: center;
        /*align-items: center;*/
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .contact-details-item{
        display: flex;
        gap: 2px;

        flex-direction: column;
        border-bottom: 1px solid #8798AE;
        padding-bottom: 15px;
    }
    .contact-des{
        margin-bottom: 30px;
        font-size: 16px;
        width: 85%;
    }
}
@media (max-width: 499px){

    .contact-content{
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        gap: 50px;
        width: 100%;
    }

    .contact-des{
        margin-bottom: 30px;
        font-size: 16px;
        width: 85%;
    }
    .contact-input-container{
        display: flex;
        flex-direction: column;
        gap: 10px;
        /*background-color: greenyellow;*/
        width: 100%;
        align-items: center;
        /*background-color: greenyellow;*/
    }
    .contact-input{
        width: 90%;
        height: 50px;
        outline: none;
        border: 1px solid rgba(170, 168, 168, 0.7);
        border-radius: 10px;
        background-color: transparent;
        padding: 0px 15px;
    }
    .contact-input-textarea{
        width: 90%;
        border: 1px solid rgba(170, 168, 168, 0.7);
        border-radius: 10px;
        background-color: transparent;
        padding: 10px 15px;
        height: 90px;
    }
    .btn-width-contact{
        width: 90%;

    }



    .contact-details{
        /*background-color: green;*/
        /*justify-content: center;*/
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }
    .contact-details-item{
        display: flex;
        gap: 2px;
        width: 90%;
        flex-direction: column;
        border-bottom: 1px solid #8798AE;
        padding-bottom: 15px;
    }

}