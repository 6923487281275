.different{
    color: hsl(262, 80%, 50%);
}
.about-head{
    font-size: 3rem;
    margin-bottom: 30px;
    font-weight: 700;
}
.about-head-container{
    /*background-color: green;*/

}
.about-content{
    display: flex;
    width: 100%;
    /*background-color: green;*/
    justify-content: center;

}
.about-me{
    width: 550px;
    /*background-color: peru;*/
    /*height: 190px;*/
    height: fit-content;
    background-color: #181D25;
    padding:15px;
    border-radius: 15px;
    /*text-align: left;*/
    font-size: 17px;
text-align: justify;
}
.about-resume{
    border-radius: 15px;
    padding: 15px;
    font-size: 17px;
    gap: 20px;
    width: 550px;
    align-items: center;
    display: flex;
    flex-direction: column;
    /*background-color: purple;*/
    background-color: #181D25;
    text-align: justify;
    height: 230px;

}
.width-btn2{
    width: 520px;
}
.image-container{
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-color: #2A313D;
    margin-bottom: 30px;
    border: 2px solid hsla(262, 80%, 50%, 0.31);
}
.about-partition{
    width: 3px;
    height: 230px;
    background-color: rgba(142, 140, 140, 0.41);
    margin-right: 25px;
    margin-left: 25px;
}

@media (max-width: 900px) {
    .image-container{
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: #2A313D;
        margin-bottom: 20px;
        border: 2px solid hsla(262, 80%, 50%, 0.31);
    }
    .about-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        /*background-color: green;*/
        justify-content: center;
        /*overflow:scroll;*/
        /*height: 600px;*/
    }
    .about-partition{
        width: 90%;

        height: 2px;
        background-color: rgba(142, 140, 140, 0.41);
       margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .about-me{
        width: 90%;
        /*background-color: peru;*/
        height: fit-content;
        background-color: #181D25;
        padding:15px 15px;
        margin: auto;
        border-radius: 15px;
        /*text-align: left;*/
        font-size: 16px;
        text-align: justify;
    }
    .about-head-container{
       display: flex;
        width: 90%;
        align-items: center;
        justify-content: center;

    }
    .image-container-parent{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .about-resume{
        border-radius: 15px;
        padding: 15px;
        font-size: 16px;
        gap: 20px;
        width: 90%;
        align-items: center;
        position: relative;
        display: flex;
        flex-direction: column;
        /*background-color: purple;*/
        background-color: #181D25;
        text-align: justify;
        height: fit-content;
        margin:auto;
        margin-bottom: 3rem;

    }
    .width-btn2{
        width: 100%;
    }
}
