.service-content
{
    display: flex;
    /*background-color: green;*/
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;

    gap: 40px;
}


.service-content-item{
    width: 490px;
    height: 410px;
    background-color: #2A303C;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 35px;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(72, 61, 139, 0.4);
}
.service-image{
    height: 180px;
    width: auto;
}
.service-image-title{
    /*background-color:green;?*/
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 30px;
}


@media (max-width: 650px){
    .service-content
    {
        display: flex;
        /*background-color: green;*/
        width: 100%;
        justify-content: space-evenly;
        flex-wrap: wrap;

        gap: 0px;
    }

    .service-content-item{
        width: 90%;
        height: fit-content;
        background-color: #2A303C;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px 20px;
        flex-direction: column;
        margin-bottom: 3rem;
        box-shadow: 0 4px 8px rgba(72, 61, 139, 0.4);
    }
    .service-image-title{
        /*background-color:green;?*/
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 10px;
        margin-top: 30px;
        line-height: 2.1rem;
    }

}